<!--
 * @Author: liym
 * @Date: 2020-05-23 14:23:19
 * @LastEditors: liym
 * @LastEditTime: 2020-05-23 14:25:45
 * @Description: 描述
-->
<template>
  <iframe class='notice' :src="url"></iframe>
</template>

<script>
export default {
  name: 'MyName',
  props: {},
  components: {},
  data () {
    return {
      url: './static/notice/index.html'
    }
  },
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang='scss' scoped>
.notice {
  border: 0;
}
</style>
